import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import elementIcon from "./js/svgicon";
import utils from './js/utils.js'
import api from './api/api.js'
import publicData from './js/public.js'

// 处理element-plus自动导入，部分组件样式失效问题
import 'element-plus/theme-chalk/src/message.scss'
import 'element-plus/theme-chalk/src/message-box.scss'
import 'element-plus/theme-chalk/src/overlay.scss'

import './style/index.scss'

import { ElMessage, ElMessageBox } from 'element-plus'

// 页面标题对应router里自定义的title
router.beforeEach((to,from,next)=>{
    // console.log(store.state.token)
    if(store.state.token || to.meta.notoken ){
      if(to.path=='/'){
        next('/workbenches')
      }else{
        next();
      }
        // next();
    }else{
      next({path: '/login', query: {redirect: to.path}})
    }
})

const app = createApp(App)

app.config.globalProperties.utils = utils;
app.config.globalProperties.$public = publicData;
app.config.globalProperties.$api = api;
app.config.globalProperties.$message = ElMessage;
app.config.globalProperties.$confirm = ElMessageBox.confirm;
app.config.globalProperties.$msgbox = ElMessageBox;

app.provide("$api", api);

app.use(store).use(router).use(elementIcon).mount('#app')
