import { createRouter, createWebHashHistory } from 'vue-router';

export const routes = [



  
  {
    path: '/login',
    name: 'login',
    meta: { notoken: true },
    component: () => import('@/views/login/login.vue'),
  },
  {
    path: '/password',
    name: 'password',
    meta: { notoken: true },
    notoken: true,
    component: () => import('@/views/login/password.vue'),
  },
  {
    path: '/',
    title: '数据统计',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/workbenches',
        name: 'workbenches',
        component: () => import('@/views/system/workbenches.vue'),
        meta: { titL: ['数据统计'], tab: '/workbenches', },
      },
    ],
  },
  {
    path: '/',
    title: '招生合作',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/courseOrder',
        name: 'courseOrder',
        component: () => import('@/views/order/courseOrder.vue'),
        meta: { titL: ['招生合作'], tab: '/courseOrder', keepAlive: true },
      },
      // {
      //   path: '/userInfo/:id',
      //   name: 'userInfo',
      //   component: () => import('@/views/users/info.vue'),
      //   meta: { titL: ['用户管理'], tab: '/users' },
      // },
    ],
  },

  {
    path: '/',
    title: '设置',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/info',
        name: 'info',
        component: () => import('@/views/set/info.vue'),
        meta: { titL: ['基本信息'], tab: '/info', keepAlive: true },
      },

    ],
  },

  // Single
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
